export const filterRanges = {
    budget: [15, 3740000000],
}

export const filterSelects = {
    categories: [
        { id: 6, title: 'Работы' },
        { id: 10, title: 'Прочие работы' },
        { id: 11, title: 'Услуги' },
        { id: 14, title: 'ПИР' },
        { id: 15, title: 'МК' },
        { id: 16, title: 'ТМЦ' },
        { id: 17, title: 'Разработка КМД' },
    ],
    project: [
        { id: 1, title: "Проект тестовый" },
        { id: 2, title: "Аллегро. Колесопрокатный стан" },
        { id: 3, title: "ТРЦ Косино 2020" },
        { id: 4, title: "21-007_ГФК Энергоцетр" },
        { id: 5, title: "21-018-02_Воздушный переход" },
        { id: 6, title: "21-002_Глобус-Митино" },
        { id: 7, title: "21-022 ГОС СКЛ Иркутск" },
        { id: 8, title: "ЗМК Нижний Новгород. КАПЭКС 2021" },
        { id: 9, title: "Центр скалолазания. г. Москва, ул. 3-я Песчаная, д.2" },
        { id: 10, title: "Египет_Эль-Дабаа" },
        { id: 11, title: "21-036_Фермы А101" },
        { id: 12, title: "Складской комплекс в пос. Бугры" },
    ],
    status: [
        { id: 1, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 2, title: "Сформирован", bgColor: "has-background-primary-light" },
        { id: 3, title: "Открыт", bgColor: "has-background-info-light" },
        { id: 4, title: "Отменен", bgColor: "has-background-info-light" },
        { id: 5, title: "Подведение итогов", bgColor: "has-background-info-light" },
        { id: 6, title: "Окончен", bgColor: "has-background-success-light" },
        { id: 7, title: "Заполнение сценария", bgColor: "has-background-info-light" },
        { id: 8, title: "Проведение аукциона", bgColor: "has-background-info-light" },
        { id: 10, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 11, title: "Подана", bgColor: "has-background-info-light" },
        { id: 12, title: "Конкурс", bgColor: "has-background-info-light" },
        { id: 13, title: "Принята к рассмотрению", bgColor: "has-background-info-light" },
        { id: 14, title: "Отклонена", bgColor: "has-background-danger-light" },
        { id: 15, title: "Победитель", bgColor: "has-background-info-light" },
        { id: 16, title: "Переторг", bgColor: "has-background-info-light" },
        { id: 17, title: "На редактировании", bgColor: "has-background-info-light" },
        { id: 18, title: "На проверке", bgColor: "has-background-info-light" },
        { id: 19, title: "Проверен", bgColor: "has-background-info-light" },
        { id: 20, title: "Заблокирован", bgColor: "has-background-info-light" },
        { id: 21, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 22, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 23, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 24, title: "Утвержден", bgColor: "has-background-info-light" },
        { id: 25, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 26, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 27, title: "Утвержден", bgColor: "has-background-info-light" },
        { id: 28, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 29, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 30, title: "Утвержден", bgColor: "has-background-info-light" },
        { id: 31, title: "Отклонен", bgColor: "has-background-info-light" },
        { id: 32, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 33, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 34, title: "Утвержден", bgColor: "has-background-info-light" },
        { id: 35, title: "Не аккредитован", bgColor: "has-background-info-light" },
        { id: 36, title: "Аккредитован", bgColor: "has-background-info-light" },
        { id: 37, title: "Переаккредитация", bgColor: "has-background-info-light" },
        { id: 38, title: "На переаккредитации", bgColor: "has-background-info-light" },
        { id: 39, title: "Подготовка к переаккредитации", bgColor: "has-background-info-light" },
        { id: 40, title: "2-е место", bgColor: "has-background-info-light" },
        { id: 41, title: "3-е место", bgColor: "has-background-info-light" },
        { id: 42, title: "Согласован", bgColor: "has-background-info-light" },
        { id: 43, title: "Зарегистрирован", bgColor: "has-background-info-light" },
        { id: 44, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 45, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 46, title: "Утвержден", bgColor: "has-background-info-light" },
        { id: 47, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 48, title: "На согласовании", bgColor: "has-background-info-light" },
        { id: 49, title: "Утверждено", bgColor: "has-background-info-light" },
        { id: 50, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 51, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 52, title: "Утвержден", bgColor: "has-background-info-light" },
        { id: 53, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 54, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 55, title: "Утвержден", bgColor: "has-background-info-light" },
        { id: 56, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 57, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 58, title: "Утвержден", bgColor: "has-background-info-light" },
        { id: 59, title: "Оригинал получен", bgColor: "has-background-info-light" },
        { id: 60, title: "Черновик", bgColor: "has-background-info-light" },
        { id: 61, title: "Согласование", bgColor: "has-background-info-light" },
        { id: 62, title: "Проверен", bgColor: "has-background-info-light" },
        { id: 63, title: "Завершен", bgColor: "has-background-info-light" },
        { id: 64, title: "На проверке", bgColor: "has-background-info-light" },
        { id: 65, title: "Аннулирован", bgColor: "has-background-info-light" },
        { id: 66, title: "Согласование 1С", bgColor: "has-background-info-light" },
        { id: 67, title: "Отклонен", bgColor: "has-background-info-light" },
        { id: 68, title: "Переторг завершен", bgColor: "has-background-info-light" },
    ],
    statuses: [
        { id: 1, title: "ОТКРЫТ" },
        { id: 2, title: "ОКОНЧЕН" },
    ]
}

export const tenderColumns = [
    {
        field: "id",
        label: "ID",
        placeholder: "ID",
        width: "22",
        show: true,
    },
    {
        field: "name",
        label: "НАИМЕНОВАНИЕ",
        placeholder: "Искать",
        width: "20",
        useLinkTemplate: true,
        iSearchable: true,
        selectedItem: null,
        show: true,
    },
    {
        field: "date",
        label: "ДАТЫ ПРОВЕДЕНИЯ",
        placeholder: "Фильтр дат",
        width: "140",
        useDatesTemplate: true,
        iSearchable: true,
        show: true,
    },
    {
        field: "categories",
        label: "КАТЕГОРИЯ",
        placeholder: "Искать по категории",
        width: "20",
        selectable: true,
        selectedItem: null,
        selectList: 'categories',
        useSelectTemplate: true,
        show: true,
    },
    {
        field: "location",
        label: "ЛОКАЦИЯ",
        placeholder: "Искать по локации",
        width: "20",
        useSelectTemplate: true,
        show: true,
    },
    {
        field: "project",
        label: "ПРОЕКТ",
        placeholder: "Выберите проект",
        width: "20",
        selectable: true,
        selectedItem: null,
        selectList: 'project',
        useSelectTemplate: true,
        show: true,
    },
    {
        field: "budget",
        label: "ПРЕДВ. ЦЕНА, РУБ",
        width: "20",
        placeholder: "Выберите цену",
        rangeable: true,
        range: [],
        rangeName: 'budget',
        maxRange: 3740000000,
        useNumberTemplate: true,
        collapseIsOpen: false,
        show: true,
    },
    {
        field: "status",
        label: "СТАТУС",
        placeholder: "Выберите статус",
        width: "60",
        selectedItem: null,
        selectList: 'statuses',
        useStatusTemplate: true,
        show: true,
    },
    {
        field: "requestsCount",
        label: "ЗАЯВКИ",
        width: "20",
        show: true,
    },
    {
        field: "description",
        label: "КРАТКОЕ ОПИСАНИЕ",
        placeholder: "Искать по описанию",
        iSearchable: true,
        width: "150",
        show: true,
        hideUnderShowMore: true
    },
]
